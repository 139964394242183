<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <bookmarks />
      <quick-actions />
    </div>

    <div class="align-items-center mx-auto flex-grow-0 flex-lg-grow-1">
      <b-spinner
        v-if="isLoading"
        label="Loading..."
      />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto flex-sm-grow-1 justify-content-end">
      <locale />
      <dark-Toggler class="d-none d-lg-block" />
      <search-bar />
      <!--      <cart-dropdown />-->
      <!--      <notification-dropdown />-->
      <announcement-dropdown />
      <support-button />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BSpinner,
} from 'bootstrap-vue'
import Vue, { computed } from 'vue'
import { isDevelopment } from '@core/utils/utils'
import SupportButton from '@core/layouts/components/app-navbar/components/SupportButton.vue'
import Bookmarks from './components/Bookmarks.vue'
import QuickActions from './components/QuickActions.vue'
import Locale from './components/Locale.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
// import CartDropdown from './components/CartDropdown.vue'
// import NotificationDropdown from './components/NotificationDropdown.vue'
import AnnouncementDropdown from './components/AnnouncementDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    SupportButton,
    BLink,
    BSpinner,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    QuickActions,
    Locale,
    SearchBar,
    DarkToggler,
    // CartDropdown,
    // NotificationDropdown,
    AnnouncementDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: { isDevelopment },
  setup() {
    const isLoading = computed(() => Vue.$inProgressRequests?.value?.length || false)

    return {
      isLoading,
    }
  },
}
</script>
