<template>
  <div>
    <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
    >
      <template #button-content>
        <feather-icon
          :badge="unreadAnnouncements && unreadAnnouncements.length ? unreadAnnouncements.length : null"
          badge-classes="bg-info"
          class="text-body"
          icon="BellIcon"
          size="21"
        />
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            {{ $t('modules.announcements.p_c') }}
          </h4>
          <b-badge
            v-if="unreadAnnouncements && unreadAnnouncements.length"
            pill
            variant="light-primary"
          >
            {{ $t('modules.announcements.x_new', { n: unreadAnnouncements.length }) }}
          </b-badge>
        </div>
      </li>

      <!-- Announcements -->
      <vue-perfect-scrollbar
        v-if="announcements"
        v-once
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- System Announcements -->
        <b-link
          v-for="announcement in announcements"
          :key="announcement.id"
          v-b-modal.announcement-details
          @click="activeAnnouncement = announcement"
        >
          <b-media>
            <template #aside>
              <feather-icon
                icon="StarIcon"
                :variant="announcement.type"
              />
            </template>
            <p class="media-heading">
              <span :class="announcement.is_seen ? 'font-weight-normal' : 'font-weight-bolder'">
                {{ announcement.title }}
              </span>
            </p>
            <small class="notification-text">{{ announcement.subtitle }}</small>
            <small
              class="text-muted float-right"
              :class="{
                'font-weight-bolder': !announcement.is_seen
              }"
            >{{ announcement.date }}</small>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
    </b-nav-item-dropdown>
    <b-modal
      id="announcement-details"
      ok-variant="primary"
      :ok-title="$t('modules.announcements.actions.try_feature')"
      :cancel-title="$t('modules.announcements.actions.close')"
      modal-class="modal-info"
      size="lg"
      centered
      :title="activeAnnouncement ? activeAnnouncement.title : ''"
      @ok="activeAnnouncement && activeAnnouncement.url ? $router.push(activeAnnouncement.url) : ''"
      @show="markAnnouncementAsSeen(activeAnnouncement)"
    >
      <b-card-text v-if="activeAnnouncement">
        <div class="text-center announcement-img float-right">
          <b-img
            v-if="activeAnnouncement.image"
            :src="activeAnnouncement.image"
            alt=""
          />
        </div>
        <p
          v-for="(line, index) in activeAnnouncement.description.split('\n')"
          :key="index"
        >
          {{ line }}
        </p>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge, BCardText, BImg, BLink, BMedia, BModal, BNavItemDropdown,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { computed, ref } from 'vue'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    BModal,
    BImg,
    BCardText,
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  setup() {
    /* eslint-disable global-require */
    const announcements = ref(null)
    const activeAnnouncement = ref(null)
    const unreadAnnouncements = computed(() => {
      if (!announcements.value) {
        return null
      }
      return announcements.value.filter(a => !a.is_seen)
    })

    if (isUserLoggedIn()) {
      store.dispatch('announcements/fetchAll').then(response => {
        announcements.value = response.data.data
      })
    }

    const markAnnouncementAsSeen = announcement => {
      if (!announcement) {
        return
      }
      store.dispatch('announcements/update', { id: announcement.id }).then(response => {
        announcements.value = announcements.value.map(a => {
          const newAnnouncement = a
          if (a.id === announcement.id) {
            newAnnouncement.is_seen = true
          }
          return newAnnouncement
        })
      })
    }
    /* eslint-disable global-require */

    const systemNotifications = [
      {
        title: 'Server down',
        subtitle: 'USA Server is down due to hight CPU usage',
        type: 'light-danger',
        icon: 'XIcon',
      },
      {
        title: 'Sales report generated',
        subtitle: 'Last month sales report generated',
        type: 'light-success',
        icon: 'CheckIcon',
      },
      {
        title: 'High memory usage',
        subtitle: 'BLR Server using high memory',
        type: 'light-warning',
        icon: 'AlertTriangleIcon',
      },
    ]

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      announcements,
      activeAnnouncement,
      unreadAnnouncements,
      markAnnouncementAsSeen,
      systemNotifications,
      perfectScrollbarSettings,
    }
  },
}
</script>

<style lang="scss">
.announcement-img img {
  max-width: 100%;
  max-height: 200px;
  width: 250px;
  height: auto;
}
</style>
