<template>
  <b-navbar-nav class="nav border-left-2">
    <b-nav-item
      v-for="(action, index) in actions"
      :id="`quickaction-${index}`"
      :key="index"
      @click="action.callback({event: $event, router:$router})"
    >
      <feather-icon
        :icon="action.icon"
        size="21"
      />
      <b-tooltip
        triggers="hover"
        :target="`quickaction-${index}`"
        :title="$t(action.title)"
        :delay="{ show: 1000, hide: 50 }"
      />
    </b-nav-item>

  </b-navbar-nav>
</template>

<script>
import {
  BNavbarNav, BNavItem, BTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BNavbarNav, BNavItem, BTooltip,
  },
  methods: {
    refresh() {
      // console.log('test')
      // console.log("Current route", this.$router.currentRoute)
      this.$router.push(this.$router.currentRoute)
    },
  },
  setup() {
    const actions = [
      {
        title: 'Refresh Page', callback: args => { args.router.go(args.router.currentRoute) }, icon: 'RefreshCwIcon',
      },
    ]

    return {
      actions,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}

ul.navbar-nav {
  border-left: 2px solid #c0c0c0;
  margin-left: 10px;
  padding-left: 10px;
}
</style>
